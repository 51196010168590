.customizerSidebar {
  width: $sidebarWidth;
  background-color: $white;
  height: 100%;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 2;
  transition: 0.2s ease-in;
  margin-right: -$sidebarWidth;
  &.showCustomizer {
    margin-right: 0;
  }
}
.custombtn {
  margin-left: -58px;
  position: fixed;
  top: 50%;
  border-radius: 5px 0 0 5px;
}

/* HIDE RADIO */
[type='radio']#CEDESCA-input,
[type='radio']#CNTEC-input {
  position: absolute;
  left: -9999px;
}

/* IMAGE STYLES */
[type='radio'] + img {
  border-radius: 8px;
  box-shadow: 0 8px 8px #00000026;
  opacity: 0.4;
}

/* CHECKED STYLES */
[type='radio']:checked + img {
  outline: 2px solid rgb(43, 135, 243);
  opacity: 100%;
}

.chat-container {
  max-width: auto;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow-y: auto; /* Añade una barra de desplazamiento vertical */
  max-height: 200px; /* Establece la altura máxima para que aparezca la barra de desplazamiento */
}
.message {
  margin-bottom: 10px;
}

.user {
  font-weight: bold;
}

.content {
  margin-left: 5px;
}
